<div nz-row nzJustify="space-between" nzGutter="48">
    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }">
        <h4 nz-typography>Bulk Message</h4>

        <div class="form-input">
            <form nz-form [formGroup]="uploadCampaignForm">
                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Campaign Name</nz-form-label>
                    <nz-form-control [nzSm]="10" [nzValidatingTip]="note" [nzErrorTip]="nameErrorTpl">
                        <input nz-input placeholder="" formControlName="campaignName" />
                        <ng-template #nameErrorTpl let-control>
                            <ng-container *ngIf="control.hasError('required')">required
                            </ng-container>
                            <ng-container *ngIf="control.hasError('pattern')">{{note}}</ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>File Type</nz-form-label>
                    <nz-form-control [nzSm]="10" style="margin-left: 1px;">
                        <nz-radio-group formControlName="fileType" [(ngModel)]="fileType">
                            <label nz-radio nzValue="txt">txt</label>
                            <label nz-radio nzValue="xlsx">xlsx</label>
                            <label nz-radio nzValue="xls">xls</label>
                            <label nz-radio nzValue="csv">csv</label>
                            <label nz-radio nzValue="zip">zip</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item >
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Select File</nz-form-label>
                    <nz-form-control [nzSm]="10" style="margin-left: 1px;">
                        <!-- <div class="hg"> -->

                            <nz-upload  [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" nzAccept=".{{fileType}}">
                                <button nz-button >
                                    <span nz-icon nzType="upload" style="margin-left: 1px"></span>
                                    Select File
                                </button>
                            </nz-upload>
                        <!-- </div> -->
                        <i><small>{{totalMobileNumbers}} Mobile Numbers | Note : .txt,.xls,.csv,.xlsx,.zip are the
                                allowed file
                                types</small></i>
                    </nz-form-control>
                    <button nz-button [nzType]="'primary'" [nzLoading]="uploading" (click)="handleUpload()"
                        [disabled]="fileList.length === 0" style="margin-left: -300px">Upload</button>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" [nzXs]="10" nzRequired>Message Type</nz-form-label>
                    <nz-form-control style="margin-left: 1px;">
                        <nz-radio-group nzButtonStyle="solid" formControlName="serviceType" [(ngModel)]="messageType"
                            (ngModelChange)="getSenderIdsByMsgType($event)">
                            <label nz-radio-button nzValue="trans">Transactional</label>
                            <label nz-radio-button nzValue="promo">Promotional</label>
                            <label nz-radio-button nzValue="others">Service</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item *ngIf="messageType === 'others'">
                    <nz-form-label class="txt-left" [nzSm]="5" [nzXs]="10" nzRequired>Service Type</nz-form-label>
                    <nz-form-control>
                        <nz-radio-group nzButtonStyle="solid" formControlName="serviceSubType"
                            (ngModelChange)="getSenderIdsByMsgType(messageType,$event)">
                            <label nz-radio-button nzValue="service-implicit">Service Implicit</label>
                            <label nz-radio-button nzValue="service-explicit">Service Explicit</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Sender ID</nz-form-label>
                    <nz-form-control [nzSm]="10">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Sender Id" formControlName="senderId"
                            (ngModelChange)="getTemplates(messageType,$event)" [(ngModel)]="senderIdSelected">
                            <nz-option [nzLabel]="item.senderId" [nzValue]="item.senderId"
                                *ngFor="let item of listOfSenderIds">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Content Template Name</nz-form-label>
                    <nz-form-control [nzSm]="10">
                        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Template"
                            formControlName="templateName"
                            (ngModelChange)="templateSelected = $event;messageText = templateSelected?.templateText">
                            <nz-option [nzLabel]="item.templateTitle" [nzValue]="item"
                                *ngFor="let item of listOfTemplates">
                            </nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" [nzXs]="10" nzRequired>Message Encoding</nz-form-label>
                    <nz-form-control style="margin-left: 1px;">
                        <nz-radio-group nzButtonStyle="solid" formControlName="msgType">
                            <label nz-radio-button nzValue="plain">Plain Text</label>
                            <label nz-radio-button nzValue="unicode">Unicode</label>
                            <label nz-radio-button nzValue="FL">Flash</label>
                            <label nz-radio-button nzValue="FU">Flash Unicode</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" [nzXs]="10" nzRequired>Message Part</nz-form-label>
                    <nz-form-control style="margin-left: 1px;">
                        <nz-radio-group nzButtonStyle="solid" formControlName="msgPart"
                            [ngModel]="messageText | creditCount:'checkPart'">
                            <label nz-radio-button nzValue="single">Singlepart</label>
                            <label nz-radio-button nzValue="multi">Multipart</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Message Text</nz-form-label>
                    <nz-form-control [nzSm]="10">
                        <nz-textarea-count>
                            <!-- [ngModel]="templateSelected?.templateText" -->
                            <textarea rows="4" #textArea formControlName="msgText" nz-input (input)="msgLength()" placeholder="Message Text"
                                [(ngModel)]="messageText"></textarea>
                        </nz-textarea-count>
                        <a style="margin-left: 1px;">
                            {{msgTextLength ==0? messageText.length:msgTextLength}} characters | {{messageText | creditCount }} SMS Credit
                        <br />
                        <small><i><b>Note:</b> xxxxxx will be replaced by actual shortcode up to 6 chars.</i></small></a>

                    </nz-form-control>
                </nz-form-item>


                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Is ShortUrl Selected</nz-form-label>
                    <nz-form-control [nzSm]="10" style="margin-left: 1px;">
                        <nz-radio-group formControlName="isShortUrlSelected" [(ngModel)]="isShortUrlSelected">
                            <label nz-radio  nzValue="Y">Yes</label>
                            <!-- (click)="open()" -->
                            <label nz-radio nzValue="N">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="isShortUrlSelected === 'Y'">
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Short URL Name</nz-form-label>
                    <nz-form-control [nzSm]="10" >
                        <nz-select nzShowSearch nzAllowClear  nzMode="tags" nzShowSearch nzPlaceHolder=" Select Short URL Name" formControlName="shortUrlName"
                            (ngModelChange)="onShortUrlSelect($event,textArea)">
                            <nz-option *ngFor="let option of listOfShortUrl" [nzLabel]="option.name"
                                [nzValue]="option.name"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Entity ID</nz-form-label>
                    <nz-form-control [nzSm]="10">
                        <input nz-input placeholder="" formControlName="entityId" placeholder="Entity ID"
                            [ngModel]="templateSelected?.entityId" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Operator Template Id</nz-form-label>
                    <nz-form-control [nzSm]="10">
                        <input nz-input placeholder="" formControlName="dltTemplateId" placeholder="Operator Template Id"
                            [ngModel]="templateSelected?.operatorTemplateId" />
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item>
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Schedule Message</nz-form-label>
                    <nz-form-control [nzSm]="10" style="margin-left: 1px;">
                        <nz-radio-group formControlName="scheduleMessage" [(ngModel)]="scheduleMessage">
                            <label nz-radio nzValue="yes">Yes</label>
                            <label nz-radio nzValue="no">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="scheduleMessage === 'yes' && splitFile === 'no'">
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Schedule Date & Time</nz-form-label>
                    <nz-form-control [nzSm]="8">
                        <nz-date-picker
                        nzFormat="yyyy-MM-dd HH:mm"
                        [nzDisabledDate]="disabledDate"
                        [nzDisabledTime]="disabledDateTime"
                        [(ngModel)]="selectedDate"
                        formControlName="scheduleDateTime"
                        [nzShowNow]="false"
                        [nzShowTime]="{ nzDefaultOpenValue: timeDefaultValue, nzFormat: 'HH:mm' }"></nz-date-picker>

                    </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="scheduleMessage === 'yes' && splitFile === 'yes'">
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Schedule Date</nz-form-label>
                    <nz-form-control [nzSm]="8">
                      <nz-date-picker
                        nzFormat="yyyy-MM-dd"
                        [nzDisabledDate]="disabledDate"
                        [(ngModel)]="selectedDate"
                        formControlName="scheduleDateTime"
                        [nzShowNow]="false">
                      </nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>



                <nz-form-item *ngIf="scheduleMessage === 'yes'">
                    <nz-form-label class="txt-left" [nzSm]="5" nzRequired>Split File</nz-form-label>
                    <nz-form-control [nzSm]="8">
                        <nz-radio-group formControlName="splitFile" [(ngModel)]="splitFile">
                            <label nz-radio class="sd" nzValue="yes">Yes</label>
                            <label nz-radio nzValue="no">No</label>
                        </nz-radio-group>
                    </nz-form-control>
                </nz-form-item>

                <!-- {{splitPart.controls.length}} -->
                <ng-container formArrayName="splitPart" *ngIf="splitFile === 'yes' && scheduleMessage === 'yes'">
                    <ng-container *ngFor="let splitPartForm of splitPart.controls; let i=index" [formGroupName]="i">
                        <!-- <div *ngIf="splitPartForm.get('mm')?.value !== uploadCampaignForm.get('disabledDateTime')?.value" class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
                        <span class="font-medium"></span> {{ alertMsg }}
                      </div> -->
                      <div nz-row nzGutter="12">
                        <div nz-col [nzSm]="10" >
                          <nz-form-item>
                            <nz-form-control>
                              <!-- Existing code -->
                            </nz-form-control>
                          </nz-form-item>
                        </div>

                        <div nz-col nzSpan="2">
                          <nz-form-item>
                            <nz-form-control>
                              <input nz-input placeholder="From" formControlName="from" />
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col nzSpan="2">
                          <nz-form-item>
                            <nz-form-control>
                              <input nz-input placeholder="To" formControlName="to" />
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col nzSpan="2">
                          <nz-form-item>
                            <nz-form-control>
                              <nz-select nzPlaceHolder="Hour" formControlName="hh" >
                                <nz-option [nzLabel]="item" [nzValue]="item" [nzDisabled]="isHourDisabled(item)" *ngFor="let item of hourOption">
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col nzSpan="2">
                          <nz-form-item>
                            <nz-form-control>
                              <nz-select nzPlaceHolder="Minute" formControlName="mm">
                                <nz-option [nzLabel]="item" [nzValue]="item" *ngFor="let item of minuteOption" [nzDisabled]="isMinuteDisabled(item)">
                                </nz-option>
                              </nz-select>
                            </nz-form-control>
                          </nz-form-item>
                        </div>
                        <div nz-col nzSpan="2">
                          <button nz-button nzType="primary" (click)="addSplitArray()" *ngIf="i === 0">Add</button>
                          <button nz-button nzType="primary" (click)="removeSplitArray(i)" *ngIf="i !== 0">Remove</button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>


                <nz-form-item class="flex items-center mt-1 space-x-2" style="margin-left: 45%;">
                    <nz-form-control>

                        <button nz-button nzType="primary" (click)="submitForm()">Submit</button>
                        <button nz-button (click)="resetForm()">Clear</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>

    </div>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Preview" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()" [nzOkText]="'Send Now'"
    [nzOkLoading]="isOkLoading">
    <ng-container *nzModalContent>
        <nz-descriptions nzBordered>
            <nz-descriptions-item nzTitle="Sender ID" [nzSpan]="5">{{senderIdSelected}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Character Count" [nzSpan]="5"> {{msgTextLength ==0? messageText.length:msgTextLength}} characters
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="SMS Credit" [nzSpan]="5">{{messageText | creditCount}} SMS Credit(s)
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Message" [nzSpan]="5">{{messageText}}</nz-descriptions-item>
        </nz-descriptions>
    </ng-container>
</nz-modal>


<nz-drawer
      [nzBodyStyle]="{ overflow: 'auto' }"
      [nzMaskClosable]="true"
      [nzWidth]="820"
      [nzVisible]="visible"
      nzTitle="Create"
      (nzOnClose)="close()"
    >
  <form nz-form *nzDrawerContent [formGroup]="viewshortUrl">
    <div nz-row [nzGutter]="24">
      <div nz-col nzSpan="22">
        <!-- Name -->
        <nz-form-item>
          <nz-form-label class="txt-left" [nzSpan]="6">Name</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <input nz-input placeholder="Name" formControlName="name" />
          </nz-form-control>
        </nz-form-item>

        <!-- Is Active -->
        <nz-form-item>
          <nz-form-label class="txt-left" [nzSpan]="6">Is Active</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <nz-radio-group formControlName="isActive">
              <label nz-radio nzValue="Y">Yes</label>
              <label nz-radio nzValue="N">No</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>

        <!-- Is Dynamic -->
        <nz-form-item>
          <nz-form-label class="txt-left" [nzSpan]="6">Is Dynamic</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <nz-radio-group formControlName="isDynamic">
              <label nz-radio nzValue="Y">Yes</label>
              <label nz-radio nzValue="N">No</label>
            </nz-radio-group>
            <br />
            <small><i>If 'Is Dynamic' is selected, different short URLs will be sent to each number.</i></small>
          </nz-form-control>
        </nz-form-item>

        <!-- Select Domain -->
        <nz-form-item>
            <nz-form-label class="txt-left" [nzSm]="6" >Select Domain</nz-form-label>
            <nz-form-control [nzSm]="18">
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select Status" formControlName="domainName">
                    <nz-option [nzLabel]="host.domainName" [nzValue]="host.domainName"
                        *ngFor="let host of listActiveHost"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>

        <!-- Long URL -->
        <nz-form-item>
          <nz-form-label class="txt-left" [nzSpan]="6">Long URL</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <nz-input-group [nzAddOnBefore]="longUrlAddOnBefore">
              <input type="text" nz-input formControlName="longUrlValue" />
            </nz-input-group>
            <ng-template #longUrlAddOnBefore>
              <nz-select [ngModel]="'http://'" formControlName="longUrl" style="width:100px;">
                <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                <nz-option nzLabel="https://" nzValue="https://"></nz-option>
              </nz-select>
            </ng-template>
            <small style="word-break: break-all;">
                <i><b>Sample URL:</b>
                  google.com?mobile=%MOBILE%&camp=%CAMPAIGN_NAME%&rectime=%RECTIME%&circle=%CIRCLE%&operator=%OPERATOR%
                </i>
              </small>
          </nz-form-control>
        </nz-form-item>

        <!-- Callback URL -->
        <!-- <nz-form-item>
          <nz-form-label class="txt-left" [nzSpan]="6">Callback URL</nz-form-label>
          <nz-form-control [nzSpan]="18">
            <nz-input-group [nzAddOnBefore]="callbackUrlAddOnBefore">
              <input type="text" nz-input formControlName="callbackUrlValue" />
            </nz-input-group>
            <ng-template #callbackUrlAddOnBefore>
              <nz-select [ngModel]="'http://'" formControlName="callbackUrl">
                <nz-option nzLabel="http://" nzValue="http://"></nz-option>
                <nz-option nzLabel="https://" nzValue="https://"></nz-option>
              </nz-select>
            </ng-template>
            <small style="word-break: break-all;">
              <i><b>Sample URL:</b>
                google.com?mobile=%MOBILE%&camp=%CAMPAIGN_NAME%&rectime=%RECTIME%&circle=%CIRCLE%&operator=%OPERATOR%
              </i>
            </small>
          </nz-form-control>
        </nz-form-item> -->
        <nz-form-item>
            <nz-form-control style="margin-left: 60%;">
                <button nz-button nzType="primary" mb-3 (click)="submitshorturl()">Submit</button>
                <button nz-button (click)="resetForm()">Clear</button>
            </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</nz-drawer>