import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';


@Component({
  selector: 'app-view-templates',
  templateUrl: './view-templates.component.html',
  styleUrls: ['./view-templates.component.scss']
})
export class ViewTemplatesComponent implements OnInit {

  listOfData: any;
  disablePagination: boolean=false;
  disablePaginationpre: boolean=true;
  dataList:any
  downloadLink: string = '';
  page:number=1;
  subdisabled:boolean=true;

  listOfColumn = [
    {
      title: 'Template Title',
      compare: (a: any, b: any) => a.templateTitle.localeCompare(b.templateTitle),
      priority: false
    }, {
      title: 'Template Description',
      compare: (a: any, b: any) => a.templateDescription.localeCompare(b.templateDescription),
      priority: 3
    }, {
      title: 'Sender Id',
      compare: (a: any, b: any) => a.senderId.localeCompare(b.senderId),
      priority: 2
    }, {
      title: 'Status',
      compare: (a: any, b: any) => a.status.localeCompare(b.status),
      priority: 1
    }, {
      title: 'Content Template Type',
      compare: (a: any, b: any) => a.contentTemplateType.localeCompare(b.contentTemplateType),
      priority: 4
    },
    // {
    //   title: 'Action',
    //   compare: (a: any, b: any) => a.contentTemplateType.localeCompare(b.contentTemplateType),
    //   priority: 5
    // },


  ];


  viewform = this.fb.group({
    operatorTemplateId: ['', Validators.required],
    templateTitle: ['', Validators.required],
  })
  // operatorTemplateId: any;
  // templateTitle: any;

  constructor(
    private fb: FormBuilder,
    private storageService: StorageService,
    private reportService: ReportService,
    private utilsService: UtilsService
  ) {

    this.reportService.refresh.subscribe((data:{})=>{
      // this.listOfData = data
      this.listOfData=data
    })
  }
 anyText(){
  if(this.viewform.get('templateTitle')?.value != '' || this.viewform.get('operatorTemplateId')?.value != ''){
    this.subdisabled=false
  }else{
    this.subdisabled=true;
  }
 }

  ngOnInit(): void {
    // this.getAllTemplates();
    this.getAllTemplates();
  }
  ngOnChanges() {
    this.getAllTemplates();
  }
  OnInit(){
    this.getAllTemplates();
  }

  downloadFile() {

  }

  deleteTemplate(event: any) {
    let params = {
      loggedInUserName: this.storageService.username,
      operation: "removeTemplateFromList",
      operatorTemplateId: event.operatorTemplateId,
      templateTitle: event.templateTitle,


    }
    this.reportService.deleteContentTemplate(params).subscribe(data => {
      this.getAllTemplates();
      if (data.result.toLowerCase() === 'success') {
        this.utilsService.showSuccessMsg(data.message)
        this.getAllTemplates();
      } else {
        this.utilsService.showErrorMsg(data.message)
      }
    }, err => {
      this.utilsService.showErrorMsg("Something Went Wrong");
    })
  }

  refresh(){
    let params = {
      "loggedInUserName": this.storageService.username,
      "pagination": this.page,
      "recordRange": 10
    }

    this.reportService.getAllContentTemplates(params).subscribe(data => {
      this.listOfData = data?.data?.contentTemplateList;
      this.downloadLink = data?.data?.downloadReportLink ? data?.data?.downloadReportLink.match(/href='(.*?)'/)[1] : null;

    })

    this.getAllTemplates();
  }

  getAllTemplates() {
    let params = {
      "loggedInUserName": this.storageService.username,
      "pagination": this.page,
      "recordRange": 10
    }

    this.reportService.getAllContentTemplates(params).subscribe(data => {
      this.listOfData = data?.data?.contentTemplateList;
      this.downloadLink = data?.data?.downloadReportLink ? data?.data?.downloadReportLink.match(/href='(.*?)'/)[1] : null;

    })
    this.reportService.viewRefresh(this.listOfData)
  }

  search() {
    let dt = {
      loggedInUserName: this.storageService.username,
      operatorTemplateId: this.viewform.get('operatorTemplateId')?.value,
      templateTitle: this.viewform.get('templateTitle')?.value,
    };
    this.reportService.searchContentTemplate(dt).subscribe(
      (data) => {
        this.listOfData = data?.data?.contentTemplate;
        console.log(this.listOfData);
      },
    );
    // this.viewform.reset();
  }

  resetAddBlacklistForm(){
    this.viewform.reset()
  }

  next() {
    let params = {
    "loggedInUserName": this.storageService.username,
    "pagination": ++this.page,
    "recordRange": 10
  }

  this.reportService.getAllContentTemplates(params).subscribe(data => {
    // alert(data?.data?.contentTemplateList)
    if (data?.data?.contentTemplateList.length === 0) {
      // Handle the case where data is null, e.g., disable pagination
      this.disablePagination = true;
      // Exit the function early since there's no data
    }
    this.disablePaginationpre = false;
    this.listOfData = data?.data?.contentTemplateList;
    this.downloadLink = data?.data?.downloadReportLink ? data?.data?.downloadReportLink.match(/href='(.*?)'/)[1] : null;

  })
    // this.currentPageIndex++;
  }

  prev() {
    let params = {
      "loggedInUserName": this.storageService.username,
      "pagination": --this.page,
      "recordRange": 10
    }

    this.reportService.getAllContentTemplates(params).subscribe(data => {
      if (this.page === 1) {
        // Handle the case where data is null, e.g., disable pagination
        this.disablePaginationpre = true;
        // Exit the function early since there's no data
      }
      this.disablePagination = false;
      this.listOfData = data?.data?.contentTemplateList;
      this.downloadLink = data?.data?.downloadReportLink ? data?.data?.downloadReportLink.match(/href='(.*?)'/)[1] : null;

    })
    // this.currentPageIndex--;
  }




     submit(){

      this.getAllTemplates();
     }
     showEditModal(e:any){
     console.log(e.target.value)
     }

}
