<div nz-row nzJustify="space-between" >
    <div nz-col [nzXs]="{ span: 20 }" [nzSm]="{ span: 6 }">
        <div nz-col nzSpan="30">
        <div class="card-group">
            <div class="card">
                <!-- <div class="logo"><img
                        src="https://raw.githubusercontent.com/dasShounak/freeUseImages/main/Visa-Logo-PNG-Image.png"
                        alt="Visa"></div>
                <div class="chip"><img src="https://raw.githubusercontent.com/dasShounak/freeUseImages/main/chip.png"
                        alt="chip"></div> -->
                <div class="name" style="font-size: 15px;"><small>You Are Login As</small></div>
                <div class="namee">{{username}}</div>
             <!-- <div class="number">Welcome Back!</div> -->
             <hr>
            <!-- Display Available Credits only if isWalletEnabled is 'N' -->
<div *ngIf="isWalletEnabled === 'N'">
    <div class="number" style="font-size: 15px;">
      <small>Available Credits</small>
    </div>
    <div class="to">{{ availableCredits }}</div>
  </div>
  
  <!-- Display Available Balance only if isWalletEnabled is 'Y' -->
  <div *ngIf="isWalletEnabled === 'Y'">
    <div class="number" style="font-size: 15px;">
      <small>Available Balance</small>
    </div>
    <div class="to">{{ availableBalance }}</div>
  </div>
  
                <!-- <div class="from">10/19</div> -->
                <div class="ring"></div>
                <hr>
            </div>
        </div>
        </div>
        
        <br>
        <br>

        <div class="dashboard-counter">
            <div nz-row nzJustify="space-between">
                <div nz-col [nzXs]="{ span: 24 }">
                    <div class="counter" nz-row>
                        <div  nz-col [nzXs]="{ span: 12 }">Submitted to Telco</div>
                        <div  nz-col [nzXs]="{ span: 8 }">{{summaryReportData?.totalSubmit}}</div>
                        <div  nz-col [nzXs]="{ span: 4 }">{{getPercentage(summaryReportData?.totalSubmit,summaryReportData?.totalRequest)}}%</div>

                    </div>
                </div>
                <div nz-col [nzXs]="{ span: 24 }">
                  <div class="counter" nz-row>
                    <div  nz-col [nzXs]="{ span: 12 }"> Platform Rejection</div>
                    <div  nz-col [nzXs]="{ span: 8 }">{{summaryReportData?.totalRejected}}</div>
                    <div  nz-col [nzXs]="{ span: 4 }">{{getPercentage(summaryReportData?.totalRejected,summaryReportData?.totalRequest)}}%</div>
                </div>
                </div>
            </div>
            <div nz-row nzJustify="space-between">
                <div nz-col [nzXs]="{ span: 24 }">
                  <div class="counter" nz-row>
                    <div  nz-col [nzXs]="{ span: 12 }">DN Success</div>
                    <div  nz-col [nzXs]="{ span: 8 }">{{summaryReportData?.totalDelivered}}</div>
                    <div  nz-col [nzXs]="{ span: 4 }">{{getPercentage(summaryReportData?.totalDelivered,summaryReportData?.totalRequest)}}%</div>
                </div>
                </div>
                <div nz-col [nzXs]="{ span: 24 }">
                  <div class="counter" nz-row>
                    <div  nz-col [nzXs]="{ span: 12 }"> DN Failed</div>
                    <div  nz-col [nzXs]="{ span: 8 }">{{summaryReportData?.totalFailed}}</div>
                    <div  nz-col [nzXs]="{ span: 4 }">{{getPercentage(summaryReportData?.totalFailed,summaryReportData?.totalRequest)}}%</div>
                </div>
                </div>
                <!-- <div nz-col [nzXs]="{ span: 24 }">
                  <div class="counter" nz-row>
                    <div nz-col [nzXs]="{ span: 12 }">DN Awaited</div>
                   <div nz-col [nzXs]="{ span: 8 }">{{getDNAwaitedValue()}}</div>
                  <div nz-col [nzXs]="{ span: 4 }">{{getDNAwaitedPercentage() | number:'1.2-2'}}%</div>
                </div>
                </div> -->
            </div>
        </div>

        <!-- <app-credits-card></app-credits-card> -->
    </div>

    <div style="margin-top: 4%;" nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 17}" [nzSm]="{span:20}">
        <div class="chart-header">
            <!-- <h2>Trend Graph</h2> -->
            <h2>Overview</h2>
            

            <div class="chart-action-btns" >
                <nz-segmented [nzOptions]="graphOptions" nzSize="default" (nzValueChange)="handleIndexChange($event)" [ngClass]="{'smallScreen': isSmallScreen}">
                </nz-segmented>
            </div>
        </div>
        <br />
        <app-charts type="line" title="Overview" [chartOptions]="lineChartOptions" *ngIf="lineChartOptions" [ngClass]="{'smallScreen': isSmallScreen}">
        </app-charts>
    </div>
  </div>
  <br />
  <div nz-row nzJustify="space-between" >
    <!-- <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 6 }">
        <h2>Telco Latency (Overall)</h2>
        <app-charts type="pie" title="Telco Latency" [chartOptions]="pieChart"></app-charts>
    </div> -->

    <div nz-col [nzXs]="{ span: 24 }" [nzLg]="{ span: 24 }">
        <h2>Summary Report</h2>
        <!-- <app-simple-table [dataSet]="listOfData"></app-simple-table> -->
        <app-simple-table [listOfData]="listOfData" [listOfColumn]="listOfColumn"></app-simple-table>
    </div>
  </div>
