import { Component, EventEmitter, HostListener, Input, OnInit, Output, } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzTableSortFn } from 'ng-zorro-antd/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef, } from 'ng-zorro-antd/modal';
import { ReportService } from 'src/app/services/reports/report.service';
import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { EditModalComponent } from 'src/app/pages/content-template-mgmt/edit-modal/edit-modal.component';
import { Observable } from 'rxjs';
interface DataItem {
    summaryDate:Date,
    receiveDate:Date,
    senderId:String,
    totalRequest:number,
    totalRejected:number
    totalSubmit:number,
    totalDelivered:number,
    totalFailed:number,
  totalAwaited:number
}
@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss']
})

export class SimpleTableComponent implements OnInit {

  editTemplateForm: FormGroup;
  isSmallScreen = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }
  sortTable: any = { data: [] };
  deletedRows: any[] = [];
  @Input() listOfData: any;
  @Input() total:any;
  @Input() listOfColumn: any;
  @Input() pagination: boolean = false;
  @Input() type: string = 'general';
  singleDlrRequired: string | null = '';
  @Output() deleteTriggred: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() editTrigger:EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteRowEvent = new EventEmitter<number>();
  listOfCurrentPageData: readonly any[] = [];
  setOfCheckedId = new Set<number>();

  checked = false;
  indeterminate = false;

  expandSet = new Set<number>();
  data: any;


  constructor(private modal: NzModalService,
    private reportService: ReportService,
    private campaignService: CampaignService,
    private storageService: StorageService,
    private fb: FormBuilder,) {
      this.editTemplateForm = this.fb.group({
        templateText:[''],
        entityId:[''],
        senderId:[''],
        templateId:[''],

      });
    }



  ngOnInit(): void {
    loggedInUserName: this.storageService.username,
    this.checkScreenSize()
    this.singleDlrRequired = sessionStorage.getItem('singleDlrRequired');
  }

   checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 768; // Adjust the breakpoint as needed
  }

  ngOnChanges() {
    this.listOfCurrentPageData = this.listOfData;
  }

  downloadFilee(data: any) {
    const link = data.downloadReportLink ? data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    if (link) {
      this.reportService.downloadFilee(link);
    }
  }

  download(data: any) {
    const link = data.downloadReportLink ? data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
    if (link) {
      this.reportService.downloadFilee(link);
    }
  }

  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.deleteTriggred.emit(this.setOfCheckedId);
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  delete(data: any) {
    this.deleteTriggred.emit(data);
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

//   showDeleteConfirm(data: any): void {
//     this.modal.confirm({
//         nzTitle: 'Are you sure to delete this row?',
//         nzOkText: 'Yes',
//         nzOkType: 'primary',
//         nzOkDanger: true,
//         nzOnOk: () => {
//             this.deleteRow(data);
//         },
//         nzCancelText: 'No',
//         nzOnCancel: () => console.log('Cancel')
//     });
// }

// deleteRow(index: number): void {
//   this.deleteRowEvent.emit(index);
// }

showDeleteConfirm(data: any): void {
  console.log('Attempting to open modal.');
  // Open a confirmation modal
  this.modal.confirm({
    nzTitle: 'Are you sure to delete this row?',
    nzOkText: 'Yes',
    nzOkType: 'primary',
    nzOkDanger: true,
    nzOnOk: () => {
      
      this.deleteRowEvent.emit(data);
    },
    nzCancelText: 'No',
    nzOnCancel: () => console.log('Cancel')
  });
}












  showEditModal(data: any): void {
    const modalRef: NzModalRef = this.modal.create({
      nzTitle: 'Edit Entry',
      nzContent: EditModalComponent,
      nzComponentParams: {
        data: data,
      },
      nzFooter: null,
    });

    modalRef.afterClose.subscribe(updatedData => {
      if (updatedData) {

        console.log('Updated data:', updatedData);
      }
    });
  }



  viewAnalyticsReport(data: any) {
    this.deleteTriggred.emit(data);
  }

  onRowClick(data: any) {
    this.rowClick.emit(data);
  }
  toggleExpanded(data:any){

  }


  // sortTable(sortName: string, value: string | null, sortFn: NzTableSortFn<YourDataModel>): void {
  //   if (value) {
  //     const data = [...this.data];
  //     this.data = data.sort((a, b) => {
  //       const dateA = new Date(a.summaryDate).getTime();
  //       const dateB = new Date(b.summaryDate).getTime();
  //       if (dateA > dateB) {
  //         return sortFn.value === 'ascend' ? 1 : -1;
  //       }
  //       if (dateA < dateB) {
  //         return sortFn.value === 'ascend' ? -1 : 1;
  //       }
  //       return 0;
  //     });
  //   }
  // }
}