import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators,AbstractControl  } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/services/reports/report.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
import { distinctUntilChanged, debounceTime, takeUntil, map } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';

@Component({
  selector: 'app-detailed-report',
  templateUrl: './detailed-report.component.html',
  styleUrls: ['./detailed-report.component.scss']
})
export class DetailedReportComponent implements OnInit {
 listData:[]=[];
  total:any[]=[]
  userType:any='';
  listOfColumn = [
    {
      title: 'Receive Date',
      compare: (a: any, b: any) => a.receiveDate.localeCompare(b.receiveDate),
      priority: false,
      isFixed: true,
    }, {
      title: 'Sender ID',
      compare: (a: any, b: any) => a.senderId.localeCompare(b.senderId),
      priority: false,
      isFixed: true,
    }, {
      title: 'Message ID',
      compare: (a: any, b: any) => a.messageId - b.messageId,
      priority: false,
      isFixed: true,
    },
    {
      title: ('campaign Name '),
      compare: (a: any, b: any) => a.campaignNameb.campaignName,
      priority: false,
      isFixed: true,
    },
    {
      title: 'Mobile Number',
      compare: (a: any, b: any) => a.mobileNumber - b.mobileNumber,
      priority: false,
      isFixed: true,
    }, {
      title: 'Sent Date',
      compare: (a: any, b: any) => a.sentDate.localeCompare(b.sentDate),
      priority: false,
      isFixed: true,
    }, {
      title: 'Delivery Status',
      compare: (a: any, b: any) => a.deliveryStatus.localeCompare(b.deliveryStatus),
      priority: false,
      isFixed: true,
    },
    {
      title: 'Error Code',
      priority: true,
      isFixed: true,
    }, {
      title: 'Delivery Date Time',
      compare: (a: any, b: any) => a.deliveryDateTime.localeCompare(b.deliveryDateTime),
      priority: true,
      isFixed: true,
    }, 
    // {
    //   title: 'Type',
    //   compare: (a: any, b: any) => a.messageCount.localeCompare(b.messageCount),
    //   priority: true,
    //   isFixed: true,
    // }
  ];


  listOfData: any;
  //listOfData: Observable<any> | undefined;
  downloadLink: any;
  pageNumber: number = 1;
  mobile: any;
  senderId: any;
  campaign: any = [];
  messageId: any;
  templateId: any;
  errorDescription: any;
  table:boolean=false;

  validateForm!: FormGroup;
  ranges = { Today: [this.utilsService.getToday(), this.utilsService.getToday()] };
  today = this.utilsService.getToday();

  constructor(private route: ActivatedRoute,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private fb: FormBuilder,
    private reportService: ReportService) {
      if(localStorage.getItem('role')) {
        this.userType = localStorage.getItem('role');
      }

     }
    private destroy$: Subject<void> = new Subject<void>()

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

  ngOnInit(): void {


    let d=new Date;
    this.validateForm = this.fb.group({
      rangePicker: [[d,d]],
      mobileNumber: ['', [Validators.required, this.mobileNumberValidator]],
      messageId: [''],
      campaignNameList:[''],
      senderId: [''],
      templateId:['']
    });
    this.validateForm.get('rangePicker')?.valueChanges
    ?.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(500)
    )
    ?.subscribe(() => {
      this.getAllCampaignName();
    });
this.getAllCampaignName();

// if (this.userType !== 'web') {
//   this.listOfColumn.splice(3, 1);
// }
  }

  mobileNumberValidator(control: AbstractControl): { [key: string]: any } | null {
    const mobileNumber = control.value;

    // Check if the value is empty or has exactly 10 digits
    if (mobileNumber && !/^\d{10}$/.test(mobileNumber)) {
      return { 'invalidMobileNumber': true }; // Return an error object for invalid value
    }

    return null; // Return null for valid value or when the field is empty
  }



  getAllCampaignName() {
    let a = (this.validateForm.get('rangePicker')?.value)
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(a[0]),
      "toDate": this.utilsService.formatDate(a[1]),
      // "fromDate": '2023-09-20',
      // "toDate": '2023-09-21',
    }
    // this.reportService.getAllCampaignName(params).subscribe((data: any) => {
    //   this.campaign = data.data.campaignList;
    //   console.log(data.campaignName)
    // });

  }

  submitForm(): void {
    let form = this.validateForm.value;
    if (form.rangePicker) {
      const fromDate = form.rangePicker[0];
      const toDate = form.rangePicker[1];
      const daysDifference = differenceInCalendarDays(toDate, fromDate);
      if (daysDifference < 0 || daysDifference > 30) {
        this.utilsService.showErrorMsg('Date Range Select 30 Days');
        return;
      }
      
      this.getDetailedReport(fromDate, toDate);
    }
    this.table = true;
  }
  
  getDetailedReport(from: any = this.today, to: any = this.today, page: any = this.pageNumber) {
    let a = this.validateForm.get('rangePicker')?.value;
    
    let params = {
      "loggedInUserName": this.storageService.username,
      "fromDate": this.utilsService.formatDate(a[0]),
      "toDate": this.utilsService.formatDate(a[1]),
      campaignName: this.validateForm.get('campaignNameList')?.value,
      mobileNumber: this.mobile ? "91" + this.mobile : '',
      messageId: this.validateForm.get('messageId')?.value,
      senderId: this.validateForm.get('senderId')?.value,
      templateId: this.validateForm.get('templateId')?.value,
      pageNumber: page
    };
  
    const singleDlrRequired = sessionStorage.getItem('singleDlrRequired');
  
    if (singleDlrRequired === 'Yes') {
      this.reportService.getDetailedReportdata(params).subscribe(data => {
        this.processReportData(data);
        console.log("for yes data" + JSON.stringify(data));
      });
    }
     else {
      this.reportService.getDetailedReport(params).subscribe(data => {
        this.processReportData(data);
        console.log("for No data " + JSON.stringify(data));

      });
    }
  }
  
  // Helper function to process data
  private processReportData(data: any): void {
    this.listOfData = of(data.data.grid).pipe(
      map(data => data.map((obj: any) => ({
        receiveDate: obj.receiveDate || '-',
        messageId: obj.messageId || '-',
        mobileNumber: obj.mobileNumber || '-',
        sentDate: obj.sentDate || '-',
        senderId: obj.senderId || '-',
        deliveryStatus: obj.deliveryStatus || '-',
        deliveryErrorCode: obj.deliveryErrorCode || '-',
        deliveryDateTime: obj.deliveryDateTime || '-',
        messageText: obj.messageText || '-',
        messageCount: obj.messageCount || '-',
        errorDescription: obj.errorDescription || '-',
        templateId: obj.templateId || '-',
        campaignName: obj.campaignName || '-',
        awaitedPdu: obj.awaitedPdu || '-',
        failedPdu: obj.failedPdu || '-',
        finalDeliveryStatus: obj.finalDeliveryStatus || '-',
        successPdu: obj.successPdu || '-',
      })))
    );
  
    this.downloadLink = data.data.downloadReportLink ? data.data.downloadReportLink.match(/href='(.*?)'/)[1] : null;
  }
  

  nextPage() {
    this.pageNumber++;
    this.getDetailedReport();
  }

  previousPage() {
    if (this.table) {
      this.pageNumber--;
      const formValue = this.validateForm.value;
      this.getDetailedReport(formValue.rangePicker[0], formValue.rangePicker[1]);
    }
  }

  downloadFile() {
    this.reportService.downloadFile(this.downloadLink);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}