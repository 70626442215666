import { BlacklistComponent } from './../../pages/phonebook/blacklist/blacklist.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { profile } from 'console';
import { Observable } from 'rxjs';
import { BASE_URL, environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { UtilsService } from '../utils/utils.service';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
 public refresh:EventEmitter<any> = new EventEmitter<any>();

  constructor(private _storageService: StorageService,
    private utilServie: UtilsService,
    private _httpClient: HttpClient) { }

viewRefresh(listOfData:any){
  this.refresh.emit(listOfData)
}

  getDashboardData(): Observable<any> {
    let cred = { username: this._storageService.username };
    return this._httpClient.post(environment.dashboardCards, cred);
  }

  deleteSenderId(params: any): Observable<any> {
    return this._httpClient.post(environment.deleteSenderId, params);
  }

  deleteContentTemplate(params: any): Observable<any> {
    return this._httpClient.post(environment.deleteTemplate, params);
  }

  addSenderID(params: any): Observable<any> {
    return this._httpClient.post(environment.addSenderId, params);
  }

  getSummaryReport(params: any): Observable<any> {
    return this._httpClient.post(environment.summaryReport, params)
  }

  getAllCampaignName(params: any): Observable<any> {
    return this._httpClient.post(environment.getAllCampaignName, params)
  }

  getAllCampaignReport(params: any): Observable<any> {
    return this._httpClient.post(environment.getCampaignReport, params)
  }

  getClickerReport(params: any): Observable<any> {
    return this._httpClient.post(environment.detailedAnalyticsReport, params)
  }


  getDetailedReport(params: any): Observable<any> {
    return this._httpClient.post(environment.detailedReport, params)
  }
  getDetailedReportdata(params: any): Observable<any> {
    return this._httpClient.post(environment.detailedReportData, params)
  }

  getSenderIDReport(params: any): Observable<any> {
    return this._httpClient.post(environment.senderIDReport, params)
  }

  getCampaignIdReport(params: any): Observable<any> {
    return this._httpClient.post(environment.campaignReport, params)
  }

  getCampaignReport(params: any): Observable<any> {
    return this._httpClient.post(environment.campaignReport, params)
  }

  getGeneratedReports(params: any): Observable<any> {
    return this._httpClient.post(environment.viewGeneratedReport, params)
  }

  generateDownlaodReport(params: any): Observable<any> {
    return this._httpClient.post(environment.generateReport, params)
  }

  getCreditHistory(params: any): Observable<any> {
    return this._httpClient.post(environment.creditHistory, params)
  }

  getAllSenderIdList(params: any): Observable<any> {
    return this._httpClient.post(environment.allSenderIdList, params)
  }

  getAllContentTemplates(params: any): Observable<any> {
    return this._httpClient.post(environment.viewAllContentTemplates, params)
  }
  searchContentTemplate(params: any): Observable<any> {
    return this._httpClient.post(environment.searchContentTemplate, params)
  }
  getTelcoReport(params: any): Observable<any> {
    return this._httpClient.post(environment.telcoReport, params)
  }

  getHourlyReport(): Observable<any> {
    let cred = { loggedInUserName: this._storageService.username };
    return this._httpClient.post(environment.getHourlySummaryReport, cred)
  }

  getActiveDomain() {
    let cred = { loggedInUserName: this._storageService.username };
    return this._httpClient.post(environment.activeHostName, cred)
  }

  addviewshortUrl(params: any): Observable<any> {
    return this._httpClient.post(environment.addShortUrl, params)
  }


  listAllShortUrl(): Observable<any> {
    let cred = { loggedInUserName: this._storageService.username };
    return this._httpClient.post(environment.viewAllShortUrl, cred)
  }

  listDomainManager(): Observable<any> {
    let cred = { loggedInUserName: this._storageService.username };
    return this._httpClient.post(environment.listAlApprovedDomains, cred)
  }

  deleteDomain(params: any): Observable<any> {
    return this._httpClient.post(environment.deleteHostName, params)
  }

  deleteShortUrl(params: any): Observable<any> {
    return this._httpClient.post(environment.deleteShortUrl, params)
  }

  addNewDomain(params: any): Observable<any> {
    return this._httpClient.post(environment.addDomainName, params)
  }

  getAlalyticsReport(params: any): Observable<any> {
    return this._httpClient.post(environment.clickerAnalytics, params)
  }
  clickerreportAnalytii(params: any): Observable<any> {
    return this._httpClient.post(environment.clickerreportAnalytii, params)
  }

  profile(): Observable<any> {
    let cred = { loggedInUserName: this._storageService.username };
    return this._httpClient.post(environment.profile,cred)
  }

  updatedPassword(params: any): Observable<any> {
    return this._httpClient.post(environment.updatedPassword, params)
  }


  downloadFile(downloadURL: string, filename?: string, extension: string = '.csv'): void {
    if (!filename) {
      filename = this._storageService.username + "-" + this.utilServie.getCurrentTimestamp() + "-DownloadReport" + extension;
    }
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._storageService.accessToken);
    this._httpClient.get(downloadURL, { headers, responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }


  campaigndownload(downloadURL: string, filename?: string, extension: string = '.csv'): void {
    if (!filename) {
      filename = this._storageService.username + "-" + this.utilServie.getCurrentTimestamp() + "-DownloadReport" + extension;
    }
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._storageService.accessToken);
    this._httpClient.get(downloadURL, { headers, responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }
  downloadFilee(downloadURL: string, filename?: string, extension: string = '.zip'): void {
    if (!filename) {
      filename = this._storageService.username + "-" + this.utilServie.getCurrentTimestamp() + "-DownloadReport" + extension;
    }
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._storageService.accessToken);
    this._httpClient.get(downloadURL, { headers, responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }

  downloadClickerAnalytics(downloadURL: string, filename?: string, extension: string = '.xlsx'): void {
    if (!filename) {
      filename = this._storageService.username + "-" + this.utilServie.getCurrentTimestamp() + "-DownloadReport" + extension;
    }
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this._storageService.accessToken);
    this._httpClient.get(downloadURL, { headers, responseType: 'blob' as 'json' }).subscribe(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }
}